import React from "react";

import layer1 from "./img/layer1.svg";
import layer2 from "./img/layer2.svg";
import layer3 from "./img/layer3.svg";
import payimg from "./img/Uniteller_Visa_MasterCard_MIR.png";

const StepOne = props => {
	return (
		<div>
			<h1 className="title__h1">Сервис отложенного платежа</h1>
			<p className="text">
				Отложенное пополнение – это возможность пополнить баланс транспортной
				карты или продлить срок действия проездного билета через интернет на
				сайте{" "}
				<a style={{ textDecoration: "underline" }} href="https://transkart.ru/">
					www.transkart.ru
				</a>{" "}
				с использованием безналичной оплаты
			</p>

			<div className="info-items-list">
				<div className="info-item">
					<div className="info-item__ico-wrp">
						<img src={layer1} alt="Приобретение проездного билета" />
						<i className="card-ico" />
					</div>
					<div className="info-item__desc">
						<strong>Приобретение проездного билета</strong>
					</div>
				</div>
				<div className="info-item">
					<div className="info-item__ico-wrp">
						<img
							src={layer2}
							alt="Оплата проездного билета без комиссии с помощью банковской карты"
						/>
						<i className="money-ico" />
					</div>
					<div className="info-item__desc">
						<strong>
							Оплата проездного билета без комиссии с помощью банковской карты
						</strong>
						<div style={{ marginTop: "20px" }}>
							<img src={payimg} alt="payimg" />
						</div>
					</div>
				</div>
				<div className="info-item">
					<div className="info-item__ico-wrp">
						<img
							src={layer2}
							alt="Запись ресурса на транспортную карту в салоне транспортного
							средства на терминале у кондуктора"
						/>
						<i className="calc-ico" />
					</div>
					<div className="info-item__desc">
						<strong>
							Запись ресурса на транспортную карту в салоне транспортного
							средства на терминале у кондуктора
						</strong>
					</div>
				</div>
				<div className="info-item">
					<div className="info-item__ico-wrp">
						<img src={layer3} alt="Инструкция для пользователя" />
						<i className="info-ico" />
					</div>
					<div className="info-item__desc">
						<strong>
							<a href="https://transkart.ru/assets/instructions.docx" download>
								Инструкция для пользователя
							</a>
						</strong>
					</div>
				</div>
			</div>
			<button onClick={props.onClick} className="btn-default btn-mobile-center">
				Перейти к оплате
			</button>
		</div>
	);
};

export default StepOne;
