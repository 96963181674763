import React, { Component } from "react";
import { getStoredData, deleteData } from "./base";

import PopupMess from "./PopupMess";
import "./payment.scss";

import StepOne from "./firstStep";
import StepTwo from "./secondStep";

class Payment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isToggleOn: true,
			showPopup: false,
			showPopupFail: false,
			value: "",
			id: "",
			pdcode: "",
			type: "",
			mindate: "",
			summa: "",
			payinfo: "",
			email: "",
			messError: ""
		};
	}

	componentDidMount() {
		const url = new URL(document.location.href);
		const result = url.searchParams.get("return");

		if (result !== null) {
			if (String(result) === "ok") {
				setTimeout(() => {
					// this.payment();
					this.togglePopup();
				}, 0);
			} else {
				this.setState({
					messError: "Повторите позднее или обратитесь в техническую поддержку!"
				});
				this.togglePopupFail();
			}
		}
	}

	togglePopup = () => {
		this.setState({
			showPopup: !this.state.showPopup
		});
	};

	togglePopupFail = () => {
		this.setState({
			showPopupFail: !this.state.showPopupFail
		});
	};

	delData() {
		deleteData();
	}

	handleClick = () => {
		this.setState(state => ({
			isToggleOn: !state.isToggleOn
		}));
	};

	render() {
		let isDisplay = this.state.isToggleOn;

		return (
			<div className="container">
				<React.Fragment>
					{this.state.showPopup ? (
						<PopupMess
							text="Платеж выполнен успешно!"
							mess="Важно! Во время первой оплаты проезда через терминал попросите кондуктора записать ресурс на Вашу карту."
							closePopup={this.togglePopup}
						/>
					) : null}
					{this.state.showPopupFail ? (
						<PopupMess
							text="Платеж не зачислен!"
							mess={this.state.messError}
							closePopup={this.togglePopupFail}
						/>
					) : null}
				</React.Fragment>
				<div className="container-ins">
					<ul className="breadcrumb">
						<li>
							<a href="/">Главная</a>
						</li>
						<li>


							<span>Сервис отложенного платежа</span>
						</li>
					</ul>
					{isDisplay ? <StepOne onClick={this.handleClick} /> : <StepTwo />}
				</div>
			</div>
		);
	}
}
export default Payment;
