import React, { Component } from "react";

export default class PopupMess extends Component {
	render() {
		return (
			<div className="popup">
				<div className="popup_inner">
					<h1 className={this.props.styles}>{this.props.text}</h1>
					<div>{this.props.mess}</div>
					<button onClick={this.props.closePopup}>Понятно</button>
				</div>
			</div>
		);
	}
}
