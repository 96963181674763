import React from "react";
import Loader from "react-loaders";

export const loader = <Loader type="ball-spin-fade-loader" active />;

export const optionsCard = [
	{ value: 0, label: "Билет длительного пользования" },
	{ value: 1, label: "Другой" }
];

export const customStyles = {
	option: (provided, state) => ({
		...provided,
		padding: 20
	}),
	container: () => ({
		marginBottom: 50,
		// width: 434,
		border: "1px solid #E1E5E7",
		borderRadius: 4,
		position: "relative",
		padding: 0
	}),
	valueContainer: base => ({
		...base,
		padding: "0px 8px"
	}),
	input: () => ({
		height: 64,
		minHeight: 64
	}),
	control: () => ({
		height: 64,
		minHeight: 64,
		display: "flex"
	}),
	indicatorSeparator: () => ({ display: "none" })
};

export function storeData(data) {
	localStorage.setItem("data", JSON.stringify(data));
}

export function getStoredData() {
	return JSON.parse(localStorage.getItem("data"));
}

export function deleteData() {
	localStorage.removeItem("data");
}
