import React, { Component, Fragment } from "react";
import Select from "react-select";
import PopupMess from "./PopupMess";

import { formSubmit } from "./sendUniteller";
import { customStyles, loader } from "./base";

const re = /^[0-9\b]+$/;
const minAmount = 100;
const maxAmount = 3000;
const paymentTypeCard = 1;
const paymentTypeSpb = 13;

class StepTwo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			value: "",
			inputValue: "",
			id: "",
			mess: "",
			pbtext: "",
			viewBilet: Array,
			data: null,
			error: false,
			isOpenForms: false,
			selectedOption: 1,
			optionsLoaded: false,
			options: null,
			notice: false,
			maxres: "",
			numPercent: "",
			showPopup: false,
			// percent: "",
			price: "",
			finishPrice: "",
			email: "",
			phone: "",
			formErrors: { email: "", phone: "" },
			emailValid: false,
			phoneValid: false,
			formValid: false,
			paymentType: null
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	handleSelect = selectedOption => {
		this.setState({
			selectedOption,
			value: "",
			isOpenForms: false
		});
	};

	handleSelectTarifs = selectedOption => {
		this.setState({ viewBilet: selectedOption });
		setTimeout(() => {
			this.getPrice();
		}, 0);
	};

	validPrice(event) {
		if (Number(this.state.viewBilet.pdclass) === 2) {
			if (
				Number(event) < minAmount ||
				Number(event) > this.state.viewBilet.maxres
			) {
				this.setState({ notice: true });
			} else {
				this.setState({ notice: false });
			}
		} else {
			if (Number(event) < minAmount || Number(event) > maxAmount) {
				this.setState({ notice: true });
			} else {
				this.setState({ notice: false });
			}
		}
	}

	costChange = event => {
		let cost = event.target.value;

		if (cost === "" || re.test(cost)) {
			this.validPrice(cost);

			this.setState({
				price: cost,
				finishPrice: Number(cost)
			});
		}
	};

	focusValue = event => {
		this.validPrice(event.target.value);
	};

	handleChange = e => {
		e.preventDefault();

		if (e.target.value === "" || re.test(e.target.value)) {
			this.clear();
			this.setState({
				value: e.target.value
			});

			this.validateField(e.target.value);
		}
	};

	clear() {
		this.setState({
			viewBilet: Array,
			percent: "",
			price: "",
			finishPrice: "",
			optionsLoaded: false,
			options: null,
			isOpenForms: false,
			error: false,
			mess: ""
		});
	}

	validateField(value) {
		if (value !== "" && (value.length === 10 || value.length === 16)) {
			// console.log("отправляем");
			this.setState({ isLoading: true });
			//проверяем карту
			this.checkCard(value);
		} else {
			this.clear();
		}
	}

	handleLoadOptions = (input, callback) => {
		let options = this.state.viewBilet;
		this.setState({
			optionsLoaded: true,
			options: options
		});
	};

	maybeLoadOptions = () => {
		if (!this.state.optionsLoaded) {
			this.handleLoadOptions();
		}
	};

	checkCardResponse(res) {
		const data = res;
		// console.log("пришли данные\n", data);
		try {
			let code = data.responseXML.getElementsByTagName("code")[0].childNodes[0]
				.nodeValue;
			let mess = data.responseXML.getElementsByTagName("comment")[0]
				.childNodes[0].nodeValue;

			if (Number(code) !== 0) {
				this.setState({
					error: true,
					mess: mess,
					isOpenForms: false
				});
			} else {
				let id = data.responseXML.getElementsByTagName("id")[0].childNodes[0]
					.nodeValue;
				let pbtext = data.responseXML.getElementsByTagName("pbtext")[0]
					.childNodes[0].nodeValue;
				let tickets = data.responseXML.getElementsByTagName("ticket");

				const array = Array.prototype.slice.call(tickets);
				let viewBilet = [];

				if (Number(array[0].getAttribute("pdclass")) === 2) {
					for (let prop of array) {
						viewBilet.push({
							value: prop.getAttribute("pdcode"),
							label: prop.getAttribute("pdname"),
							mindate: prop.getAttribute("mindate"),
							maxdate: prop.getAttribute("maxdate"),
							pdclass: prop.getAttribute("pdclass"),
							maxres: prop.getAttribute("maxres") * 0.01
						});
					}
				} else {
					for (let prop of array) {
						viewBilet.push({
							value: prop.getAttribute("pdcode"),
							label: prop.getAttribute("pdname"),
							mindate: prop.getAttribute("mindate"),
							maxdate: prop.getAttribute("maxdate"),
							pdclass: prop.getAttribute("pdclass")
						});
					}
				}

				this.setState({
					error: false,
					isOpenForms: true,
					mess: mess,
					id: id,
					pbtext: pbtext,
					viewBilet: viewBilet
				});

				// проверяем на сколько можно пополнить
				// if (viewBilet.length === 1) {
				// 	this.setState({
				// 		viewBilet: {
				// 			value: Number(this.state.viewBilet[0].value),
				// 			label: this.state.viewBilet[0].label,
				// 			mindate: this.state.viewBilet[0].mindate,
				// 			maxdate: this.state.viewBilet[0].maxdate,
				// 			pdclass: this.state.viewBilet[0].pdclass
				// 		}
				// 	});
				// 	this.getPrice();
				// }
			}
		} catch {
			this.setState({
				error: true,
				mess: "Error",
				isOpenForms: false
			});
		}
	}

	checkCard(value) {
		const self = this;
		const xmlhttp = new XMLHttpRequest();
		xmlhttp.open("POST", `https://transkart.ru/api/payment/checkCard`, true);
		xmlhttp.setRequestHeader("Content-type", "application/json");

		xmlhttp.onreadystatechange = function() {
			if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
				// console.log(this.responseText);
				self.setState({
					data: this,
					isLoading: false
				});
				self.checkCardResponse(this);
			}
		};

		xmlhttp.onerror = self.error;

		xmlhttp.overrideMimeType("text/xml; charset=utf-8");
		const data = {
			card: value
		};
		xmlhttp.send(JSON.stringify(data));
	}

	getPrice() {
		const self = this;

		const xmlhttp = new XMLHttpRequest();
		xmlhttp.open("POST", `https://transkart.ru/api/payment/getPrice`, true);
		xmlhttp.setRequestHeader("Content-type", "application/json");

		xmlhttp.onreadystatechange = function() {
			if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
				// console.log(this.responseText);

				let priceNode = this.responseXML.getElementsByTagName("price")[0]
					.childNodes[0].nodeValue;

				//прайс приходит в копейках
				let price = priceNode * 0.01;
				const totalPrice = minAmount > price ? minAmount : price;

				self.setState({
					price: totalPrice,
					percent: "",
					finishPrice: totalPrice
				});
			}
		};

		xmlhttp.onerror = self.error;

		xmlhttp.overrideMimeType("text/xml; charset=utf-8");
		const data = {
			printnum: self.state.value,
			id: self.state.id,
			pdcode: Number(self.state.viewBilet.value),
			date: self.state.viewBilet.mindate
		};
		xmlhttp.send(JSON.stringify(data));
	}

	error() {
		this.setState({
			error: true,
			mess: "Error",
			isOpenForms: false
		});
	}

	handleUserInput = e => {
		const name = e.target.name;
		const value = e.target.value;

		this.setState({ [name]: value }, () => {
			this.validation(name, value);
		});
	};

	validation(fieldName, value) {
		let fieldValidationErrors = this.state.formErrors;
		let emailValid = this.state.emailValid;
		let phoneValid = this.state.phoneValid;

		switch (fieldName) {
			case "email":
				if (value !== "") {
					emailValid = value.match(
						/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/i
					);
					fieldValidationErrors.email = emailValid ? "" : " is invalid";
				} else {
					fieldValidationErrors.email = "";
				}
				break;
			case "phone":
				if (value !== "") {
					phoneValid = value.match(
						/^\+?[78]?[\s\-\\(]*\d{3}[\s\-\\)]*\d{3}\D?\d{2}\D?\d{2}$/i
					);
					fieldValidationErrors.phone = phoneValid ? "" : " is invalid";
				} else {
					fieldValidationErrors.phone = "";
				}
				break;
			default:
				break;
		}

		this.setState(
			{
				formErrors: fieldValidationErrors,
				emailValid: emailValid,
				phoneValid: phoneValid
			},
			this.validateForm
		);
	}

	validateForm() {
		this.setState({
			formValid: this.state.emailValid || this.state.phoneValid
		});
	}

	errorClass(error) {
		return error.length === 0 ? "" : "show";
	}

	handleSubmit = event => {
		event.preventDefault();

		this.togglePopup();
		// Скрываем модалку с предупреждением вернуться на сайт
		// this.setState({
		// 	showPopup: !this.state.showPopup
		// });
	};

	handleSpbPayment = event => {
		this.state.paymentType = JSON.stringify({
			[paymentTypeSpb]: [this.state.finishPrice, this.state.finishPrice]
		});
		this.handleSubmit(event);
	};

	handleCardPayment = event => {
		this.state.paymentType = JSON.stringify({
			[paymentTypeCard]: [this.state.finishPrice, this.state.finishPrice]
		});
		this.handleSubmit(event);
	};

	togglePopup = () => {
		let form = document.forms["sender"];
		form["Email"].value = this.state.email;
		form["Order_IDP"].value = this.state.id;
		form["Subtotal_P"].value = this.state.finishPrice;
		form["PaymentTypeLimits"].value = this.state.paymentType;

		// Запись с localStorage
		// storeData({
		// 	card: this.state.value,
		// 	id: this.state.id,
		// 	pdcode: Number(this.state.viewBilet.value),
		// 	type: 1,
		// 	mindate: this.state.viewBilet.mindate,
		// 	summa: this.state.finishPrice,
		// 	payinfo: this.state.id,
		// 	abonent: this.state.email
		// });

		const storeData = {
			card: this.state.value,
			id: this.state.id,
			pdcode: Number(this.state.viewBilet.value),
			type: 1,
			mindate: this.state.viewBilet.mindate,
			summa: this.state.finishPrice,
			payinfo: this.state.id,
			abonent: this.state.email
		};

		formSubmit(form, storeData);
	};

	render() {
		const { isLoading } = this.state;
		return (
			<>
				{this.state.showPopup ? (
					<PopupMess
						styles="allert"
						text="Обратите внимание!"
						mess="После оплаты необходимо вернуться на страницу сервиса, нажав на кнопку 'Вернуться на сайт магазина'"
						closePopup={this.togglePopup}
					/>
				) : null}
				<div className="loaders">{isLoading && loader}</div>
				<h1 className="title__h1">Оплата сервиса</h1>

				{this.state.selectedOption && (
					<>
						<p>Введите номер вашей транспортной карты</p>

						<form
							className="order-form"
							name="sender"
							onSubmit={this.handleSpbPayment}
						>
							<input type="hidden" name="Shop_IDP" value="" />
							<input type="hidden" name="Order_IDP" value="" />
							<input type="hidden" name="Email" value="" />
							<input type="hidden" name="Subtotal_P" value="" />
							<input type="hidden" name="URL_RETURN" value="" />
							<input type="hidden" name="IData" value="" disabled />
							<input type="hidden" name="Lifetime" value="300" />
							<input type="hidden" name="OrderLifetime" value="1800" />
							<input type="hidden" name="PaymentTypeLimits" value="" disabled />
							<input type="hidden" name="Customer_IDP" value="" disabled />
							<input type="hidden" name="Signature" value="" />
							<input
								type="hidden"
								name="URL_RETURN_OK"
								value="http://transkart.ru/payment/?return=ok"
							/>
							<input
								type="hidden"
								name="URL_RETURN_NO"
								value="http://transkart.ru/payment/?return=fail"
							/>
							<div
								className={
									!this.state.error
										? "input-row row"
										: "input-row row input-row--error-show"
								}
							>
								<div className="col-lg-9 col-md-7">
									<input
										type="text"
										className="input__styles"
										value={this.state.value}
										onChange={this.handleChange}
									/>
								</div>
								<div className="col-lg-3 col-md-5">
									<span className="error-text">{this.state.mess}</span>
								</div>
							</div>

							<div
								className={
									this.state.isOpenForms ? "form-field show" : "form-field"
								}
							>
								<div className="info-row-list">
									<div className="info-row row">
										<div className="col-md-5">
											<span>Статус карты</span>
										</div>
										<div className="col-md-7">
											<span>{this.state.mess}</span>
										</div>
									</div>
									<div className="info-row row">
										<div className="col-md-5">
											<span>Ваш тариф</span>
										</div>
										<div className="col-md-7">
											<span>{this.state.pbtext}</span>
										</div>
									</div>

									<div className="select-item select-item--tarif brb-22">
										<Select
											styles={customStyles}
											placeholder="Вид проездного билета"
											searchable={true}
											onBlurResetsInput={false}
											onCloseResetsInput={false}
											autoload={false}
											isSearchable={false}
											onChange={this.handleSelectTarifs}
											options={this.state.options}
											onFocus={this.maybeLoadOptions}
										/>
									</div>
								</div>
							</div>

							<div
								className={
									this.state.isOpenForms ? "form-field show" : "form-field"
								}
							>
								<strong className="form-field-title">Сумма для оплаты</strong>

								<div className="input-row row">
									{/* notice-wrp */}
									<div className="col-lg-9 col-md-7 ">
										{/* show */}
										<div
											className={
												this.state.notice ? "red-notice show" : "red-notice"
											}
										>
											{Number(this.state.viewBilet.pdclass) === 2 ? (
												<>
													Введите сумму от 100 до {this.state.viewBilet.maxres}{" "}
													руб.
												</>
											) : (
												<>Введите сумму от 100 до 3 000 руб.</>
											)}
										</div>

										{Number(this.state.viewBilet.pdclass) === 2 ? (
											<input
												type="text"
												className="input__styles"
												value={this.state.price}
												onChange={this.costChange}
												onFocus={this.focusValue}
												placeholder="Сумма пополнения"
											/>
										) : (
											<input
												type="text"
												value={this.state.price}
												className="input__styles"
												readOnly
												placeholder="Сумма пополнения"
											/>
										)}
									</div>
									<div className="col-lg-3 col-md-5">
										<span className="error-text">Ошибка</span>
									</div>
								</div>

								<div className="info-row-list">
									{/* <div className="info-row row notice-wrp">
										<div className="col-md-5 col-sm-7">
											Сервисный сбор{" "}
											<span>
												<StaticQuery
													query={graphql`
														query {
															allFile(
																filter: {
																	relativeDirectory: { eq: "brokerage-fee" }
																}
															) {
																edges {
																	node {
																		childMarkdownRemark {
																			frontmatter {
																				name
																				code
																			}
																		}
																	}
																}
															}
														}
													`}
													render={rev => {
														let numPercent =
															rev.allFile.edges[0].node.childMarkdownRemark
																.frontmatter.code;
														return numPercent;
													}}
												/>{" "}
												%
											</span>
											<i className="qustion-ico" />
											<div className="default-left-notice">
												Вознаграждение, перечисляемое плательщиком, совершающим
												платеж при помощи банковских карт МИР, VISA или
												MasterCard с использованием настоящего сайта, за
												предоставление возможности совершения на сайте
												финансовых транзакций и обеспечении информационного
												взаимодействия между участниками расчетов (плательщиком,
												банком-эквайером, получателем денежных средств,
												держателем сайта) при обработке совершенного платежа.
												<br />
												Совершая платеж, плательщик подтверждает свое
												добровольное согласие на перечисление сервисного сбора
											</div>
										</div>
										<div className="col-md-7 col-sm-5">
											{this.state.percent ? (
												<span>{this.state.percent} руб.</span>
											) : (
												<span> не взымается </span>
											)}
										</div>
									</div>
									 */}
									<div className="info-row info-row--gradient row">
										<div className="col-md-5 col-sm-7">
											<span>Итого к оплате</span>
										</div>
										<div className="col-md-7 col-sm-5">
											<strong>{this.state.finishPrice} руб.</strong>
										</div>
									</div>
								</div>
							</div>

							<div
								className={
									this.state.isOpenForms ? "form-field show" : "form-field"
								}
							>
								<strong className="form-field-title">
									Укажите вашу электронную почту, чтобы получить чек в
									электронном виде
								</strong>
								<div className="input-row row">
									<div className="col-lg-9 col-md-7">
										<input
											type="email"
											value={this.state.email}
											name="email"
											className="input__styles"
											onChange={this.handleUserInput}
											placeholder="Email"
										/>
									</div>
									<div className="col-lg-3 col-md-5">
										<span
											className={`error-text ${this.errorClass(
												this.state.formErrors.email
											)}`}
										>
											Ошибка
										</span>
									</div>
								</div>

								<input
									type="submit"
									disabled={!this.state.formValid || this.state.notice}
									value="Оплатить по СБП"
									className="btn-mobile-center submit__styles"
									onClick={this.handleSpbPayment}
								/>

								<input
									type="button"
									disabled={!this.state.formValid || this.state.notice}
									value="другие способы оплаты"
									className="btn-mobile-center submit__styles__secondary"
									onClick={this.handleCardPayment}
								/>

								<Fragment>
									<div
										style={{ marginTop: "50px" }}
										className="form-field-title"
									>
										<strong>Уважаемый клиент!</strong>
										<p>
											Вы можете оплатить свой заказ онлайн с помощью банковской
											карты через платежный сервис компании Uniteller. После
											подтверждения заказа Вы будете перенаправлены на
											защищенную платежную страницу Uniteller, где необходимо
											будет ввести данные для оплаты заказа. После успешной
											оплаты на указанную в форме оплаты электронную почту будет
											направлен электронный чек с информацией о заказе и данными
											по произведенной оплате.
										</p>
									</div>

									<div className="form-field-title">
										<strong>Гарантии безопасности</strong>
										<p>
											Безопасность процессинга Uniteller подтверждена
											сертификатом стандарта безопасности данных индустрии
											платежных карт PCI DSS. Надежность сервиса обеспечивается
											интеллектуальной системой мониторинга мошеннических
											операций, а также применением 3D Secure - современной
											технологией безопасности интернет-платежей.
										</p>
										<p>
											Данные Вашей карты вводятся на специальной защищенной
											платежной странице. Передача информации в процессинговую
											компанию Uniteller происходит с применением технологии
											шифрования TLS. Дальнейшая передача информации
											осуществляется по закрытым банковским каналам, имеющим
											наивысший уровень надежности.
										</p>
									</div>
									<div className="form-field-title">
										<strong>
											Uniteller не передает данные Вашей карты магазину и иным
											третьим лицам!
										</strong>
										<p>
											Если Ваша карта поддерживает технологию 3D Secure, для
											осуществления платежа, Вам необходимо будет пройти
											дополнительную проверку пользователя в банке-эмитенте
											(банк, который выпустил Вашу карту). Для этого Вы будете
											направлены на страницу банка, выдавшего карту. Вид
											проверки зависит от банка. Как правило, это дополнительный
											пароль, который отправляется в SMS, карта переменных
											кодов, либо другие способы.
										</p>
									</div>
								</Fragment>
							</div>
						</form>
					</>
				)}
			</>
		);
	}
}

export default StepTwo;
